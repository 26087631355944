import React from 'react';

import Layout from '../../../components/layout';
import Seo from '../../../components/Seo';
import ActivityBreadCrumb from '../../../components/ActivityBreadCrumbs';
import RateActivity from '../../../components/RateActivity';

const MakeARainMaker = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Make a rain maker',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pb-8 pt-8 bg-grey">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pt-5 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-12 mb-lg-16 mt-10">
                <span>Make a rain</span>
                <br />
                <span>maker</span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '160px',
                  left: '430px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(180deg)',
                  zIndex: '10',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
              <div className="ml-0 ml-xl-15">
                <h3>
                  Nero, Isla and Kai love decorating and creating lots of fun
                  crafts that are all about water!
                </h3>
                <p>
                  Get ready and join the Aquanauts to create your very own rain
                  maker. Once you’ve made it, listen to the sound it makes.
                </p>
                <div style={{ position: 'relative' }}>
                  <a
                    href="https://cdn.southeastwater.co.uk/aquasmart/mission-2/SEW-Activities-Set-02-V5_02_RainMaker.pdf"
                    target="_blank"
                    className="btn btn-light-blue mt-2 mr-sm-2 mt-sm-0"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '200px',
                      height: '135px',
                      width: '135px',
                      transform: 'rotate(180deg)',
                    }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="/images/Doodles/Arrows/Arrow-1.svg"
                      alt="Illustration of an arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src="/images/Activities/MissionTwo/SEW-Make-a-rain-maker-01.svg"
                className="w-100"
                alt="Make a rain maker"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <RateActivity activity="Make a rain maker" />
  </Layout>
);

export default MakeARainMaker;
